var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderlist bj freight-container" }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "div",
        { staticClass: "d-flex a-center" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c("div", { staticClass: "title-style" }, [
            _vm._v(" 运费结算合计："),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.tool.toDecimal2(
                    (this.liquidate_amount_total * 10000) / 100 / 100 / 10000
                  )
                ) + "元"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "seach" },
        [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "—",
                  "start-placeholder": "结算日期时间起",
                  "end-placeholder": "结算日期时间止",
                },
                on: { change: _vm.payChange },
                model: {
                  value: _vm.pay_at,
                  callback: function ($$v) {
                    _vm.pay_at = $$v
                  },
                  expression: "pay_at",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入批次号", clearable: "" },
                model: {
                  value: _vm.from1.settle_batch_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.from1, "settle_batch_no", $$v)
                  },
                  expression: "from1.settle_batch_no",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs multiple-select" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "选择城市仓",
                  },
                  on: { change: _vm.cityChange },
                  model: {
                    value: _vm.from1.store_logistics_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "store_logistics_id", $$v)
                    },
                    expression: "from1.store_logistics_id",
                  },
                },
                _vm._l(_vm.storelist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id.toString() },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs multiple-select" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "选择自提点" },
                  model: {
                    value: _vm.from1.store_delivery_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "store_delivery_id", $$v)
                    },
                    expression: "from1.store_delivery_id",
                  },
                },
                _vm._l(_vm.selflist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id.toString() },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择集配中心" },
                  model: {
                    value: _vm.from1.logistic_business_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "logistic_business_id", $$v)
                    },
                    expression: "from1.logistic_business_id",
                  },
                },
                _vm._l(_vm.logisticsList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.search },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.tableData.length || _vm.disabledExport,
                type: "warning",
                icon: "el-icon-download",
              },
              on: { click: _vm.onHandleExport },
            },
            [_vm._v("导出列表")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.tableData.length || _vm.disabledDetailsExport,
                type: "warning",
                icon: "el-icon-download",
              },
              on: { click: _vm.onHandleExportDetails },
            },
            [_vm._v("导出明细")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "order_no",
                align: "center",
                label: "结算批次号",
                width: "120",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.seettle_batch
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.seettle_batch
                                  .logistics_freight_batch_no
                              )
                            ),
                          ])
                        : _c("span", [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                align: "center",
                label: "结算时间",
                width: "130",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$empty.time(scope.row.liquidate_time)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "logistic_business_name",
                align: "center",
                label: "集配中心",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$empty.empty(scope.row.logistic_business_name)
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "城市仓" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.logistics
                        ? _c("span", [_vm._v(_vm._s(scope.row.logistics.name))])
                        : _c("span", [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "自提点" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.delivery
                        ? _c("span", [_vm._v(_vm._s(scope.row.delivery.name))])
                        : _c("span", [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "结算订单数" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_vm._v(" " + _vm._s(scope.row.order_number) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                align: "center",
                label: "运费支付金额(元)",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tool.toDecimal2(
                              (scope.row.freight_amount * 10000) / 100 / 10000
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                align: "center",
                label: "售后退费金额(元)",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tool.toDecimal2(
                              (scope.row.aftersale_amount * 10000) / 100 / 10000
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "结算比例(%)" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(" " + _vm._s(scope.row.liquidate_rate) + " "),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "service_fee_amount",
                label: _vm.serviceFeeLabel,
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "结算金额(元)" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tool.toDecimal2(
                              (scope.row.liquidate_amount * 10000) /
                                100 /
                                100 /
                                10000
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "main-color pointer text-none",
                          attrs: {
                            to: {
                              name: "freightDetail",
                              query: { id: scope.row.id },
                            },
                          },
                        },
                        [_vm._v("查看订单 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.from1.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }